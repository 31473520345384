<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>Order #{{ order.identifier }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed :to="{ name: 'module-lhl-orders' }"
              >Return to Orders</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <v-row no-gutters>
            <v-col cols="12">
              <v-card outlined class="align-self-stretch">
                <v-card-title class="grey lighten-4">Line Items</v-card-title>
                <v-divider></v-divider>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th class="text-right">Sub Total</th>
                      <th class="text-right">VAT Total</th>
                      <th class="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(line_item, i1) in order.line_items" :key="i1">
                      <td class="py-2">
                        <div class="text-subtitle-1 font-weight-bold">
                          {{ line_item.product.name }}
                        </div>
                      </td>
                      <td>
                        {{ line_item.quantity }} x {{ line_item.bag_size }}kg
                      </td>
                      <td class="text-right">£{{ line_item.sub_total }}</td>
                      <td class="text-right">£{{ line_item.vat_total }}</td>
                      <td class="text-right">£{{ line_item.total }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="4" offset="8">
              <v-card outlined class="mt-6">
                <v-card-title class="grey lighten-4">Totals</v-card-title>
                <v-divider></v-divider>
                <v-simple-table class="text-right">
                  <tbody>
                    <tr>
                      <th>Items Subtotal</th>
                      <td>£{{ order.sub_total }}</td>
                    </tr>
                    <tr>
                      <th>Delivery</th>
                      <td>£{{ order.delivery_total }}</td>
                    </tr>
                    <tr>
                      <th>VAT Total</th>
                      <td>£{{ order.vat_total }}</td>
                    </tr>
                    <tr>
                      <th>Order Total</th>
                      <td>£{{ order.total }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-select
            label="Order Status"
            v-model="status"
            :items="statuses"
            :loading="statusLoading"
            hint="Update the Order Status here"
            outlined
            persistent-hint
            @change="statusChanged()"
          ></v-select>
          <v-card outlined class="mt-6">
            <v-card-title class="grey lighten-4">Delivery Address</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div>{{ order.shipping_address.full_name }}</div>
              <div>{{ order.shipping_address.line_1 }}</div>
              <div v-if="order.shipping_address.line_2">
                {{ order.shipping_address.line_2 }}
              </div>
              <div v-if="order.shipping_address.line_3">
                {{ order.shipping_address.line_3 }}
              </div>
              <div>{{ order.shipping_address.city }}</div>
              <div v-if="order.shipping_address.county">
                {{ order.shipping_address.county }}
              </div>
              <div>{{ order.shipping_address.postcode }}</div>
              <div v-if="order.shipping_address.country">
                {{ order.shipping_address.country }}
              </div>
              <div class="mt-2">
                <strong>Phone Number:</strong>
                {{ order.shipping_address.phone }}
              </div>
              <div class="mt-2">
                <strong>Email Address:</strong>&nbsp;
                <a :href="`mailto:${order.customer.email}`">{{
                  order.customer.email
                }}</a>
              </div>
            </v-card-text>
          </v-card>
          <v-card outlined class="mt-6">
            <v-card-title class="grey lighten-4">Billing Address</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div>{{ order.billing_address.full_name }}</div>
              <div>{{ order.billing_address.line_1 }}</div>
              <div v-if="order.billing_address.line_2">
                {{ order.billing_address.line_2 }}
              </div>
              <div v-if="order.billing_address.line_3">
                {{ order.billing_address.line_3 }}
              </div>
              <div>{{ order.billing_address.city }}</div>
              <div v-if="order.billing_address.county">
                {{ order.billing_address.county }}
              </div>
              <div>{{ order.billing_address.postcode }}</div>
              <div v-if="order.billing_address.country">
                {{ order.billing_address.country }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Orders",
          disabled: false,
          exact: true,
          to: {
            name: "module-lhl-orders",
          },
        },
        {
          text: "Individual Order",
          disabled: true,
        },
      ],
      statuses: [
        { text: "Processing", value: "processing" },
        { text: "Pending Payment", value: "pending-payment" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Complete", value: "complete" },
      ],
      status: null,
      statusLoading: false,
    };
  },

  computed: {
    order() {
      return this.$store.state.lhl.orders["order"];
    },
  },

  mounted() {
    this.status = this.order.status;
  },

  methods: {
    statusChanged: function () {
      this.statusLoading = true;

      this.$store
        .dispatch("lhl/orders/saveOrder", {
          appId: this.$route.params.id,
          orderId: this.order.id,
          fields: {
            status: this.status,
          },
        })
        .then(() => {
          this.statusLoading = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Order Status Updated Successfully!",
            color: "success",
          });
        })
        .catch(() => (this.statusLoading = false));
    },
  },
};
</script>
